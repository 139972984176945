.filter-box {
  display: flex;
  align-items: center;
  padding: 1rem 10px;
  border: 2px solid $lightGreyBg;
  background-color: $lightGreyBg;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @include breakpoint(tablet) {
    flex-direction: column;
  }

  .filters {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .filter-row {
      display: flex;
      margin: 5px 0;

      .days-filter {
        display: flex;
        flex-grow: 1;
        align-items: center;

        p {
          width: 85px;
          color: $black;
          font-weight: 600;

          @include breakpoint(tablet) {
            font-size: 12px;
            margin-right: 10px;
          }
        }

        .days {
          width: 100%;
          display: flex;
          border-radius: 2px;
          border: 1px solid $lightBorder;
          background-color: $white;

          div {
            padding: 5px;
            flex-grow: 1;
            font-size: 13px;
            text-align: center;
            border: 0.5px solid $lightBorder;
            border-bottom: none;
            border-top: none;

            &.active {
              color: $white;
              font-weight: 600;
              background: $lightBlue;
            }

            &:hover {
              color: $white;
              cursor: pointer;
              background: $lightBlue;
            }
          }
        }
      }

      .date-filter {
        display: flex;
        align-items: center;

        @include breakpoint(tablet) {
          margin-top: 10px;
        }

        p {
          color: $black;
          font-weight: 600;
          margin-right: 15px;
          margin-left: 15px;

          @include breakpoint(tablet) {
            margin-left: 0;
          }
        }
      }

      .text-filter {
        flex-grow: 1;
        display: flex;
        align-items: center;

        p {
          width: 85px;
          color: $black;
          font-weight: 600;
        }

        .text-input {
          flex-grow: 1;
          flex-basis: 100%;

          input {
            width: 100%;
            font-size: 16px;
            height: 30px;
          }
        }
      }

      .clear-filter-button {
        align-self: center;
        width: 125px;
        display: flex;
        margin-left: 15px;
        align-items: center;
        text-decoration: underline;

        @include breakpoint(tablet) {
          width: 100px;
        }

        svg {
          margin-left: 5px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .filter-button {
        min-width: 130px;
        margin-left: 10px;
        font-weight: $bold;
        text-transform: none;
      }
    }
  }
}

.time-selector-container {
  display: flex;
  padding: 0 25px;
  justify-content: space-between;

  div {
    flex-basis: 50%;

    .time-label {
      display: flex;
      align-items: center;
    }

    .time-selector {
      width: 235px;
      margin: 10px 0 15px;

      div {
        border: 0.5px solid $lightBorder;
        border-radius: 3px;
      }
    }
  }
}

.datetime-button-container {
  display: flex;
  padding: 10px 25px;
  justify-content: flex-end;
}
