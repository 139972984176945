.chart-container.ops {
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  
  // dimensions for test
  width: 300px;
  
  // Styles above for test

  .lane, .gutter {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .gutter {
    z-index: 2;
    padding: 2.5rem 0;
    margin-left: -5px;
    margin-bottom: -25px;
    background-color: transparent;

    .gutter-arrow {
      z-index: 2;
      display: flex;
      height: 150px;
      align-items: center;
    
      .arrow {
        width: 80px;
        height: 20px;
        line-height: 20px;
        position: relative;
        text-align: center;
        display: inline-block;
        background-color: $lightBlue;
      
        &::after {
          color: $lightBlue;
          display: inline-block;
          content: '';
          position: absolute;
          top: 0;
        }
        &::before {
          content: '';
          color: #fff;
          display: inline-block;
          position: absolute;
          top: 0;
        }
      
        .arrow-tick {
          left: 0;
          right: 0;
          top: -3px;
          padding-left: 10px;
          position: absolute;
        }

        &.right {
          &::after {
            right: -15px;
            border-left: 15px solid;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
          &::before {
            left: 0;
            border-left: 15px solid;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
        }

        &.left {
          &::after {
            left: -15px;
            border-right: 15px solid;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
          &::before {
            right: -3px;
            border-right: 15px solid;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
        }
      }
    }

    .empty-gutter {
      height: 155px;
    }
  }

  .gutter-empty {
    z-index: 2;
    width: 80px;
    padding: 2.5rem 0;
    margin-left: -5px;
    margin-bottom: -25px;
    background-color: transparent;

    .empty-gutter {
      height: 155px;
      width: 80px;
    }
  }

  .lane {
    z-index: 1;
    margin: 0 0 45px;
    padding: 1rem 0 0;
    border-radius: 5px;
    border: 1px solid $darkGreyBg;
    background-color: $lightGreyBg;

    p {
      margin: 0;
      padding: 0;
      font-weight: $bold;
    }

    .circle {
      z-index: 1;
      margin: 1rem 0;
      position: relative;
      
      .text-circle {
        display: flex;
        width: 120px;
        height: 120px;
        padding: 18px;
        text-align: center;
        border-radius: 25%;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border: 2px solid $lightBlue;

        &.success {
          font-weight: $bold;
          background-color: $success;
          border: 2px solid $success;

          span {
            color: $white;
          }
        }

        &.error {
          font-weight: $bold;
          background-color: $error;
          border: 2px solid $error;

          span {
            color: $white;
          }
        }

        span {
          font-size: 0.8em;
          color: $lightBlue;
        }
      }

      .step-circle {
        top: -80px;
        bottom: 0;
        margin: auto;
        display: flex;
        position: absolute;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border: 2px solid $lightBlue;
        
        width: 60px;
        height: 60px;

        &.left {
          left: -40px;
        }

        &.right {
          right: -40px;
        }

        span {
          font-size: 28px;
          color: $lightBlue;
        }

        &.success {
          border: 2px solid $success;

          span {
            color: $success;
          }
        }

        &.error {
          border: 2px solid $error;

          span {
            color: $error;
          }
        }
      }
  
      .revesal {
        top: 45%;
        width: 40px;
        height: 20px;
        right: -38px;
        background: $red;
        position: absolute;

        img {
          position: absolute;
          right: -10px;
          top: -5px;
        }

        &.left {
          left: -38px;

          img {
            left: -10px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .small-arrow {
        top: 42%;
        width: 0;
        height: 0;
        left: -10px;
        position: absolute;
        border-top: 12px solid transparent;
        border-right: 12px solid $lightBlue;
        border-bottom: 12px solid transparent;
      }
    }

    &:last-child {
      margin-left: -1px;
    }

    .empty-lane {
      height: 90px;
      margin: 2rem 0;
    }


    .lane-arrow {
      z-index: 2;
      height: 70px;
      position: relative;
      margin: -35px 0 -40px;

       img {
        top: 16px;
        bottom: 0;
        
        position: absolute;

        &.left {
          left: -153px;
          
        }

        &.right {
          left: -35px;
          transform: rotate(270deg);
        }
        
      }
    }
  }
}

@keyframes glide-right {
  0% {
    left: -275px;
  }
  100% {
    left: 200px;
  }
}

@keyframes glide-left {
  0% {
    right: -550px;
  }
  100% {
    right: 200px;
  }
}

@keyframes glide-down {
  0% {
    top: -75px;
  }
  100% {
    top: 200px;
  }
}

.arrow.right span {
  overflow: hidden;
  position: relative;

  &:after {
    background: #fff;
    content: "";
    z-index: 20;
    height: 155px;
    animation: glide-right 2s linear infinite;
    opacity: .5;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    width: 25px;
    z-index: 10;
  }
}

.arrow.left span {
  overflow: hidden;
  position: relative;

  &::before {
    background: #fff;
    content: "";
    z-index: 20;
    height: 155px;
    animation: glide-left 2s linear infinite;
    opacity: .5;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    width: 25px;
    z-index: 10;
  }
}

.arrow.down span {
  overflow: hidden;
  position: relative;

  &:after {
    background: #fff;
    content: "";
    z-index: 20;
    height: 20px;
    animation: glide-down 2s linear infinite;
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(195deg);
    width: 50px;
    z-index: 10;
  }
}

.arrow span {
  &.active-1 {
    &:after {
      animation-delay: 500ms;
    }
  }
  &.active-2 {
    &:after {
      animation-delay: 1500ms;
    }
  }
  &.active-3 {
    &:after {
      animation-delay: 2500ms;
    }
  }
  &.active-4 {
    &:after {
      animation-delay: 3500ms;
    }
  }
  &.active-5 {
    &:after {
      animation-delay: 4500ms;
    }
  }
}

.popover {
  p {
    text-align: center;
    font-Size: 14px;
  }
}



