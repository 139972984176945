.reports {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  .report-card {
    display: flex;
    padding: 1rem;
    width: 32%;
    margin: 1rem 0;
    margin-right: 1.25rem;
    background: $white;
    align-items: center;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    svg {
      margin-right: 1rem;
      font-size: 2.5rem;

      &.fa-file-pdf {
        color: $red;
      }

      &.fa-file-csv,
      &.fa-file-excel {
        color: $green;
      }

      &.fa-file-word {
        color: $darkBlue;
      }
    }

    p:first-child {
      font-weight: $bold;
    }

    p {
      line-height: 22px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.03);
    }
  }
}

.report-tabs.nav-tabs.nav-tabs {
  margin: 0;
  width: 20%;

  .nav-item .nav-link {
    color: #000;

    &::after {
      background-color: #000;
    }
  }
}

.transaction-tabs {
  margin-left: 10px;
}
.transaction-tabs.nav-tabs.nav-tabs {
  margin: 0;

  width: 100%;
  line-height: 0;

  a {
    padding: 1rem 5px;
  }

  .nav-item .nav-link {
    color: #000;

    &.active {
      font-weight: 900;
      color: #0098d8;
    }
    &:hover {
      font-weight: 900;
    }

    &::after {
      width: 0;
      font-weight: 800;
    }
  }
}
