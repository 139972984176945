#startDateId,
#endDateId {
  padding: 0;
  width: 100px;
  color: $black;
  font-size: 14px;

  &.DateInput_input__focused {
    border-bottom: 2px solid $darkBlue;
  }
}

.DateInput {
  line-height: 20px;
  width: auto !important;
}

.DateInput_input {
  padding: 5px !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  color: $white !important;
  background: $darkBlue !important;
  border: 1px solid $darkBlue !important;
}

.DateRangePicker_picker {
  z-index: 12 !important;
}

.DateRangePickerInput_arrow_svg {
  margin-right: 10px;
}

.DateRangePickerInput__withBorder {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.datetime-button-container {
  display: flex;
  padding: 10px 25px;
  justify-content: flex-end;
}

.dates {
  position: relative;

  .MuiTooltip-popper {
    top: 200px !important;
    left: 30px !important;
  }
}

#startDateId.DateInput_input__focused,
#endDateId.DateInput_input__focused {
  background: $lightBlue;
  color: #fff;
  font-weight: 600;
}

.react-datepicker {
  border: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.react-datepicker__header {
  background: #fff !important;
  border-bottom: none !important;
}

.react-datepicker__month {
  padding: 10px;
  border: 'none';
}

.react-datepicker__month > div > div,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 6px 10px !important;
  font-size: 16px !important;
}

.react-datepicker__day-names {
  margin: 10px 0 -10px;
  background: aliceblue;
}

.react-datepicker__day--selected {
  color: #0082d9 !important;
  background-color: #fff !important;
  border-bottom: 2px solid #0082d9;
  border-radius: 0 !important;
}
