.loader {
  display: flex;
  color: #000;
  padding: 1rem 0;
  overflow-y: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;


  &.large {
    height: 100vh;

    .heading {
      font-size: 36px;
    }
  }

  &.large.top {
    height: 300px;
  }

  .heading {
    z-index: 1;
    font-size: 20px;
    text-align: center;
  }

  .sub-heading {
    z-index: 1;
    color: #4b4b4b;
    font-size: 18px;
    text-align: center;
  }
}

@keyframes heart-beat {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.loading-error-container {
  margin-top: 1rem;

  p {
    color: $red;

    span {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}