/*********************
COLOLRS
*********************/
$white: #ffffff;
$lightGrey: rgba(0, 0, 0, 0.54);
$black: #000000;
$darkBlue: #0098d8;
$lightBlue: #01c1fe;
$highlightedBlue: #65d2ff;

// Secondary
$red: #d46565;
$success: #8ed465;
$error: #d46565;
$green: #8ed465;
$orange: #df813b;

// Borders
$lightBorder: #cfcfcf;

// Background
$lightGreyBg: #f1f1f1;
$darkGreyBg: #ececec;

// Font Weights
$bold: 600;

/*********************
BREAKPOINTS
*********************/
@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 70em) { @content; }
 }
  @else if $point == tablet {
    @media (max-width: 75em) { @content; }
 }
}
