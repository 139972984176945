.table {
  z-index: 0;
  position: relative;
  overflow: auto;

  .material-icons {
    font-size: 18px;
  }

  .MuiTableSortLabel-icon {
    opacity: 1;
  }

  .pHead{
    tr:first-child{
      th:nth-last-child(2){
        border: thin solid lightgrey;
      }
    }
  }

  thead {
    tr {
      th {
        background: #f1f1f1;
        padding: 5px;
        border: thin solid lightgrey;
      }
    }
  }

  .sHead{
    tr {
      th {
        background: #f1f1f1;
        padding: 5px;
        border: none;
        font-weight: 600;
      }

      th:first-child {
        padding-left: 15px;
        border: none;
      }

      th:last-child {
        padding-right: 15px;
        border: none;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: #fff;
    }

    td {
      padding: 5px;
    }

    td:first-child {
      padding-left: 15px;
    }

    td:last-child {
      padding-right: 15px;
    }

    td div {
      background: #fff;
    }

    td {
      color: #4a4a4a;

      .table-link {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }

      svg {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .text-filter {
    max-width: 120px;
    margin-top: -4px;
  }

  div {
    box-shadow: none;
    background: #f1f1f1;

    tfoot {
      tr {
        .MuiTablePagination-selectIcon {
          top: 4px;
        }
      }
    }
  }

  .empty-table-result {
    width: 100%;
    position: absolute;
    top: 10%;
    background: transparent;
  }
}

// Transaction table specific styles
.transaction-table {
  thead {
    tr {
      th {
        background: #f1f1f1;
        padding: 5px;
        border: none;
      }
    }
  }

  tr td:nth-last-child(2),
  tr th:nth-last-child(2) {
    text-align: right;
  }

  div {
    div:nth-of-type(2) { 
      div div{
        vertical-align: top !important;
        padding-top: 20px;
      }
    }
  }
}

// Recon session table specific styles
.session-table {
  //th:first-child {
  //  padding-left: 15px;
  //  border: none;
  //}
  //
  //th:last-child {
  //  padding-right: 15px;
  //  border: none;
  //}
  tr td:nth-last-child(2),
  tr th:nth-last-child(2),
  tr td:nth-last-child(3),
  tr th:nth-last-child(3),
  tr td:nth-last-child(4),
  tr th:nth-last-child(4),
  tr td:nth-last-child(5),
  tr th:nth-last-child(5),
  tr td:nth-last-child(6),
  tr th:nth-last-child(6),
  tr td:nth-last-child(7),
  tr th:nth-last-child(7),
  tr td:nth-last-child(10),
  tr th:nth-last-child(10){
    text-align: right;
  }
}

// Recon Analysis table specific styles
.analysis-table {
  tr td:nth-last-child(1),
  tr th:nth-last-child(1),
  tr td:nth-last-child(2),
  tr th:nth-last-child(2),
  tr td:nth-last-child(3),
  tr th:nth-last-child(3),
  tr td:nth-last-child(4),
  tr th:nth-last-child(4),
  tr td:nth-last-child(5),
  tr th:nth-last-child(5),
  tr td:nth-last-child(6),
  tr th:nth-last-child(6),
  tr td:nth-last-child(7),
  tr th:nth-last-child(7),
  tr th:nth-last-child(8),
  tr td:nth-last-child(8),
  tr td:nth-last-child(9),
  tr th:nth-last-child(9) {
    text-align: right;
  }
}

// Recon Settlement table specific styles
.settlement-table {
  tr td:nth-last-child(1),
  tr th:nth-last-child(1),
  tr td:nth-last-child(2),
  tr th:nth-last-child(2),
  tr td:nth-last-child(3),
  tr th:nth-last-child(3),
  tr td:nth-last-child(4),
  tr th:nth-last-child(4),
  tr td:nth-last-child(5),
  tr th:nth-last-child(5),
  tr td:nth-last-child(6),
  tr th:nth-last-child(6),
  tr td:nth-last-child(7),
  tr th:nth-last-child(7) {
    text-align: right;
  }
}

// Recon Exceptions table specific styles
.exceptions-table {
  tr td:nth-last-child(1),
  tr th:nth-last-child(1),
  tr td:nth-last-child(2),
  tr th:nth-last-child(2) {
    text-align: right;
  }
}

// Transaction Detail Table
.detail-table {
  height: 100%;
  background: $lightGreyBg;
  border: 2px solid $lightGreyBg;

  tr {
    td {
      padding: 10px;
      border-bottom: none;
    }
  }

  tr:nth-child(even) {
    background: $white;
  }

  .heading-cell {
    font-weight: 600;
  }

  .detail-table-actions {
    display: flex;
    justify-content: left;

    div {
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        margin-left: 3px;
        margin-right: 6px;
        text-decoration: underline;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.till-table{
  thead {
    tr {
      th {
        background: #f1f1f1;
        padding: 5px;
        border: none;
      }
    }
  }
}

// Dashboard Tables
.dashboard-table {
  thead {
    tr {
      th {
        background: #f1f1f1;
        padding: 5px;
        border: none;
      }
    }
  }
  .MuiToolbar-root {
    padding-left: 15px;
  }

  tr td:last-child {
    text-align: right;
  }

  tr th:last-child {
    text-align: right;
    padding-right: 0;
  }

  tr td:nth-last-child(2),
  tr th:nth-last-child(2) {
    text-align: right;
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
  background: rgba(51, 51, 51, 0.8);
  padding: 10px;
  font-size: 12px;
  color: #fff;
}

.table thead tr th{
  font-size: 14px;
  &:last-child.recon-table-footer {
    padding-right: 15px;
  }
}

.recon-dashboard-table {
  z-index: 0;
  position: relative;
  overflow: auto;
  outline: black;
  outline-style: auto;

  .MuiTableSortLabel-icon {
    opacity: 1;
  }

  tbody {
    tr:nth-child(1) {
      background: #0098d8;
    }

    tr:nth-child(2) {
      background: #fff;
    }

    tr:nth-child(3) {
      background: #fff;
    }

    tr:nth-child(4) {
      background: #fff;
    }

    tr:nth-child(5) {
      background: #fff;
    }

    td {
      padding: 5px;
    }

    td:first-child {
      padding-left: 15px;
    }

    td:last-child {
      padding-right: 15px;
    }

    td div {
      background: #fff;
    }

    td {
      color: #4a4a4a;

      .table-link {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }

      svg {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .text-filter {
    max-width: 120px;
    margin-top: -4px;
  }

  div {
    box-shadow: none;
    background: #f1f1f1;
    outline: black;

    tfoot {
      tr {
        .MuiTablePagination-selectIcon {
          top: 4px;
        }
      }
    }
  }
}