.notification-bar {
  display: flex;
  padding: 0.2rem;
  margin-top: 1rem;
  background: $white;
  justify-content: space-between;
  transition: all .2s ease-in-out;

  &.success {
    border: 2px solid $success;
  }

  &.error {
    border: 2px solid $error;
  }

  .impact-container {
    color: $white;
    display: flex;
    min-width: 140px;
    align-items: center;
    padding: 0.8rem 1rem;
    justify-content: space-between;

    p {
      line-height: 20px;
      font-weight: $bold;
      margin-right: 2rem;
    }

    &.success {
      background-color: $success;
    }

    &.error {
      background-color: $error;
    }
  }
  
  .description-container {
    flex-grow: 1;
    padding: 0.8rem 1rem;

    p {
      line-height: 20px;
    }
    p:first-child {
      font-weight: $bold;
    }

    .toggle-link {
      font-weight: $bold;
      color: $darkBlue;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .resolved-container {
    display: flex;
    min-width: 262px;
    padding: 0.8rem 1rem;
    flex-direction: column;
    justify-content: center;

    p {
      line-height: 20px;
      font-weight: $bold;

      &.success {
        color: $success;
      }

      &.error {
        color: $error;
      }
    }
  }

  &:hover {
    transform: scale(1.01);
  }
}