.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}

.footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  flex-shrink: 0;
}

footer {
  height: 50px;
  flex-shrink: 0;
}

main {
  flex: 1 0 auto;
}

// Setup
@import 'styles/modules/reset';
@import 'styles/modules/variables';
@import 'react-redux-toastr/src/styles/index';

// Custom MUI Table
@import 'styles/components/table';

// Custom Components
@import 'styles/components/page404';
@import 'styles/components/navigation';
@import 'styles/components/filterBox';
@import 'styles/components/help';
@import 'styles/components/datepicker';
@import 'styles/components/saveSearch';
@import 'styles/components/modal';
@import 'styles/components/transactionChart';
@import 'styles/components/opsChart';
@import 'styles/components/metrics';
@import 'styles/components/graphFilters';
@import 'styles/components/loader';
@import 'styles/components/storeSearch';
@import 'styles/components/notificationBar';
@import 'styles/components/reports';
@import 'static/webfonts/all.css';
