.fourOFourcontainer {
  flex: 1;
  display: flex;
  height: 100vh;
  margin-top: -50px;
  flex-direction: column;
  justify-content: center;

  h1, a {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  a, a:hover, a:active, a:visited {
    color: $darkBlue;
    text-decoration: none;
  }

  .error-container {
    text-align: center;
    font-weight: 800;
    margin-top: 2rem;
    font-family: 'Catamaran', sans-serif;

    span {
      font-size: 180px;
    }
  }

  .error-container > span {
    display: inline-block;
    line-height: 0.7;
    position: relative;
    color: $darkBlue;
  }

  .error-container > span > span {
    display: inline-block;
    position: relative;
  }

  .error-container > span:nth-of-type(1) {
    perspective: 1000px;
    perspective-origin: 500% 50%;
    color: $lightBlue;
  }

  .error-container > span:nth-of-type(1) > span {
    transform-origin: 50% 100% 0px;
    transform: rotateX(0);
    animation: easyoutelastic 8s infinite;
  }
  
  .error-container > span:nth-of-type(3) {
    perspective: none;
    perspective-origin: 50% 50%;
    color: $darkBlue;
  }
  
  .error-container > span:nth-of-type(3) > span {
    transform-origin: 100% 100% 0px;
    transform: rotate(0deg);
    animation: rotatedrop 8s infinite;
  }
}


@keyframes easyoutelastic {
  0% {
    transform: rotateX(0);
  }
  9% {
    transform: rotateX(210deg);
  }
  13% {
    transform: rotateX(150deg);
  }
  16% {
    transform: rotateX(200deg);
  }
  18% {
    transform: rotateX(170deg);
  }
  20% {
    transform: rotateX(180deg);
  }
  60% {
    transform: rotateX(180deg);
  }
  80% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(0);
  }
}

@keyframes rotatedrop {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(30deg);
  }
  15% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(90deg);
  }
  80% {
    transform: rotate(0);
  }
  100% {
    transform: rotateX(0);
  }
}