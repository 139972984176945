.hidden-axis-values {
  >div {
    >div {
      >svg {
        >g {
          >g:nth-child(2) {
            >g:nth-child(even) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.graph-filters {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  >div {
    width: 100%;
    display: flex;
    margin: 10px 5px;
    align-items: center;
    flex-direction: row;
  }

  @include breakpoint(desktop) {
    justify-content: flex-start;
  }
}