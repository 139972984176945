.metric-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px 15px;
  background: $lightGreyBg;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .metric-block {
    color: $white;
    flex-basis: 48%;
    margin: 1rem 0 0;
    border-radius: 5px;
    padding: 2.5rem 2rem;
    background-color: $darkBlue;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

    &.red {
      background-color: $red;
    }

    &.orange {
      background-color: $orange;
    }

    &.green {
      background-color: $green;
    }

    .title-block {
      .title-heading {
        font-size: 1.5rem;
      }
    }

    .main-value {
      display: flex;
      font-size: 3rem;
      align-items: center;
      justify-content: space-between;


      @include breakpoint(tablet) {
        font-size: 2rem;
      }
    }
  }
}