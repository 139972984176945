.help-text-container {
  padding: 2rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 16px;
    font-weight:600;
  }

  h2 {
    font-size: 16px;
    font-weight:600;
  }

  h3 {
    font-size: 16px;
    font-weight:600;
    margin-top: 15px;
  }

  ul {
    list-style: outside;
    margin-left: 25px;
  }
}

.help-welcome-paragraph{
  font-size: 1.1rem;
}

.help {
  position: relative;
}

.help-icon-container {
  position: absolute;
  top: -10px;
  right: 0;

  svg.help-icon {
    font-size: 14px;
  }
}

.contact-icons{
  i{
    vertical-align: middle;
  }
}

.icon{
  font-weight: 100;
  font-size: 16px;
  &.fa.fa-at{
    color: 	#c5352e;
  }&.fa.fa-phone{
    color: 	#000000;
  }
}