.saved-search-container {
  display: flex;
  padding: 1rem;
  align-items: center;
  background-color: $white;
  border: 2px solid $lightGreyBg;

  .save-options {
    display: flex;
    justify-content: space-between;

    span,
    a {
      color: $black;
      margin-right: 1.5rem;
      text-decoration: underline;

      svg.export-icon {
        font-size: 20px;
        margin-right: 9px;

        &.pdf {
          color: $red;
        }

        &.csv {
          color: #1f6f45;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.loading-link {
        text-decoration: none;

        &:hover {
          cursor: none;
        }
      }
    }
  }

  .saved-search {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    .dropdown-options {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dropdown-action {
        color: $red;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}