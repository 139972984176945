.store-search-container {
  width: 100%;
  display: flex;
  align-items: center;

  &.dashboard {
    margin: 1rem 0 2rem;  
  }

  p {
    font-weight: $bold;
    margin-right: 10px;
  }

  > div {
    flex: 1;
  }
}