.modal-link {
  cursor: pointer;
  text-align: right;
  text-decoration: underline
}

.modal-header {
  display: flex;
  align-items: center;
  background-color: $darkGreyBg;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

  p {
    flex-grow: 1;
  }

  svg:hover {
    cursor: pointer;
  }
}
