.page-title {
  font-size: large;
  font-weight: 600;
  margin: 1rem 0 1rem 1rem;
  white-space: pre-wrap;
}
.table-title {
  font-size: medium;
  font-weight: 500;
  margin: 1rem 0 1rem 1rem;
  white-space: pre-wrap;
}

.navigation-container {
  background: $darkBlue;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

.nav-tabs {
  display: flex;
  color: $white;
  width: 100%;
  margin: auto;
  max-width: 1920px; 
  justify-content: flex-start;

  .nav-item {
    .nav-link {
      color: $white;
      font-size: 16px;
      margin-bottom: -10px;
      display: inline-block;
      text-decoration: none;

      &::after {
        width: 0;
        height: 2px;
        content: '';
        display: block;
        margin-top: 10px;
        background: $white;
        transition: width .3s;
      }
      
      svg {
        margin-right: 0.5rem;
      }

      &:hover {
        cursor: pointer;
        &::after {
          width: 100%;
          transition: width .3s;
        }
      }
      
      &.active {
        &::after {
          width: 100%;
        }
      }
    }
  }
  
  &.nav-tabs {
    .nav-item {
      .nav-link {
        border-bottom: none;
        &:hover {
          cursor: pointer;
          border-bottom: none;
          background-color: transparent;
          border-color: transparent transparent $white;
        }
      }
    }


    li:nth-last-child(2) {
      flex-grow: 1;
      text-align: right;
    }
  }

  a {
    color: $white;
    padding: 1rem 30px;
    font-size: 0.9rem;
    text-transform: none;
    text-decoration: none;
    border-bottom: 1px solid $white;
    
    svg {
      margin-right: 0.5rem;
    }
  
    &:hover {
      cursor: pointer;
    }
    
    &.active {
      
      &::after {
        width: 100%;
      }
    }
  }
}

